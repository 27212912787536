<template>
  <div class="wrapper">
    <h4>饭卡充值</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="开卡ID" prop="id">
            <el-input v-model="form.id" placeholder="请输入开卡ID" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="用户ID" prop="personId">
            <el-input v-model="form.personId" placeholder="请输入用户ID" type="number" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="20">
          <el-col :span="8">
          <el-form-item label="金额" prop="amount">
            <el-input v-model="form.amount" placeholder="请输入金额" type="number" auto-complete="off" min="0"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- <el-form-item label="描述" prop="description">
            <el-input v-model="form.description" placeholder="请输入描述" auto-complete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="充值方式" prop="transPidVal">
            <el-select v-model="form.transPidVal" placeholder="请选充值方式" style="width:100%;">
              <el-option v-for="item in payTypeList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="照片" prop="imgs">
            <upload-img :limit="1" :uploadLists="imgList" @uploadChange="uploadSuccess"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
     
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddTrans',
  components: {
    UploadImg,
  },
  data() {
    return {
      imgList: [],
      form: {},
      payTypeList:[],//充值方式list
      rules: {
        id: [{ required: true, message: '请输入开卡ID', trigger: 'blur' }],
        personId: [{ required: true, message: '输入用户ID', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        transPidVal: [{ required: true, message: '请选择充值方式', trigger: 'blur' }],
      }
    }
  },
  computed: {
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    getDicList() {
      this.getSysDictList('receipt_type', 'payTypeList')
    },
       // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    ...mapActions(['addTransCard', 'getSysDictServe']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
        this.itemData = JSON.parse(this.$route.query.item)
        console.log(this.itemData)
          this.form = {
              id: this.itemData.id,
              personId: this.itemData.tenantId,
            }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess(data) {
      this.form.imgs = data.join(',')
    },
    //区域选择
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = "addTransCard"
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '充值成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
